body {
  margin: 0;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
